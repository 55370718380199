import { useMemo } from 'react';
import { Button, Form, Result, Typography } from 'antd';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import Box from 'src/components/Box';
import FormBuilderAnswers from 'src/components/FormBuilder/FormBuilderAnswers';
import FormBuilderWizardForm from 'src/components/FormBuilder/FormBuilderWizardForm';
import RedirectConfirmModel from 'src/components/RedirectConfirmModel';
import { FormBuilderData } from 'src/types/formBuilder';
import listFormsData from './listForms.json';

const { Title } = Typography;

const listForms = listFormsData as FormBuilderData[];

const FormRequestFormPage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();

  const formData = useMemo(() => {
    return listForms.find((item) => item.id === parseInt(params.id ?? '0'));
  }, [params.id]);

  return (
    <Box>
      <RedirectConfirmModel form={form} />

      {formData ? (
        <div className="pb-2 sm:pt-2">
          <div className="mb-3 items-center justify-between sm:flex">
            <Button
              type="link"
              size="large"
              onClick={() => {
                navigate('/form-request');
              }}
              style={{ paddingLeft: 0 }}
              icon={<IoArrowBack />}
            >
              Back
            </Button>

            <Title level={2} className="!mb-0 text-center">
              {formData.name}
            </Title>

            <div className="invisible w-[80px]"></div>
          </div>

          {/*<Text>{formData.description}</Text>*/}

          {/*<div className="mb-8">*/}
          {/*  {formData.tags?.map((tag) => (*/}
          {/*    <Tag*/}
          {/*      key={tag}*/}
          {/*      bordered={false}*/}
          {/*      style={{ background: 'rgba(0, 68, 204, 0.1)' }}*/}
          {/*    >*/}
          {/*      {tag}*/}
          {/*    </Tag>*/}
          {/*  ))}*/}
          {/*</div>*/}

          <Form
            layout="vertical"
            colon={false}
            form={form}
            labelCol={{ style: { fontWeight: '600' } }}
          >
            {formData.wizardForm && (
              <FormBuilderWizardForm data={formData.wizardForm} form={form} />
            )}

            {formData.form && (
              <FormBuilderAnswers
                form={form}
                data={formData.form}
                listData={listForms}
                onSubmit={(values) => console.log('values', values)}
              />
            )}
          </Form>
        </div>
      ) : (
        <Result status="error">Not found</Result>
      )}
    </Box>
  );
};

export default FormRequestFormPage;
