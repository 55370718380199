import { FC } from 'react';
import { Form, Select } from 'antd';
import { FormBuilderFieldSelect } from 'src/types/formBuilder';

interface FieldSelectProps {
  data: FormBuilderFieldSelect;
  marginX: boolean;
  disabled: boolean;
}

const FieldSelect: FC<FieldSelectProps> = ({ data, marginX, disabled }) => {
  return (
    <Form.Item
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      label={data.label}
      name={data.name}
      rules={[{ required: data.required, message: 'This field is required' }]}
      style={{ margin: marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
    >
      <Select
        className={disabled ? 'read-only' : undefined}
        placeholder={data.placeholder}
        mode={data.mode === 'multiple' ? 'multiple' : undefined}
        options={data.options}
        allowClear
      />
    </Form.Item>
  );
};

export default FieldSelect;
