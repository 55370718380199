import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import { Form, Radio } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';
import { FaRadio } from 'react-icons/fa6';
import { FormBuilderFieldRadio } from 'src/types/formBuilder';

const modeClassName: Record<FormBuilderFieldRadio['mode'], string> = {
  simple: '',
  pill: 'radio-group-pill',
  square: 'radio-group-square',
};

const listIcons: Record<string, ReactNode> = {
  google: <FaGoogle />,
  youtube: <FaYoutube />,
  facebook: <FaFacebook />,
  instagram: <FaInstagram />,
  radio: <FaRadio />,
  ott: <FaGoogle />,
  linkedin: <FaLinkedin />,
  tiktok: <FaTiktok />,
};

interface FieldRadioProps {
  data: FormBuilderFieldRadio;
  marginX: boolean;
  disabled: boolean;
}

const FieldRadio: FC<FieldRadioProps> = ({ data, marginX, disabled }) => {
  const form = Form.useFormInstance();
  const ref = useRef<HTMLDivElement>(null);
  const breakpoint = useBreakpoint();

  const options = useMemo(() => {
    return data.options.map((item) => ({
      ...item,
      label: (
        <div style={{ textAlign: 'center' }}>
          {item.imageUrl ? (
            <>
              <img
                src={item.imageUrl}
                style={{ width: '40px', display: 'inline-block' }}
                alt="icon"
              />
              <br />
            </>
          ) : undefined}

          {item.icon ? (
            <>
              {listIcons[item.icon]}
              {data.mode === 'square' ? <br /> : undefined}
            </>
          ) : undefined}

          {item.label}
        </div>
      ),
    }));
  }, [data.mode, data.options]);

  useEffect(() => {
    if (ref.current) {
      const unsubscribe = Array.from(ref.current.children).map((item) => {
        const input = item.querySelector('input');

        const listener = () => {
          if (input?.value === form.getFieldValue(data.name)) {
            setTimeout(() => {
              form.setFieldValue(data.name, undefined);
            }, 0);
          }
        };

        item.addEventListener('click', listener);

        return () => {
          item.removeEventListener('click', listener);
        };
      });

      return () => {
        unsubscribe.forEach((callback) => callback());
      };
    }
  }, [data.name, form]);

  const columFive = 5 - (options.length % 5);
  const columnFour = 4 - (options.length % 4);
  const columnTree = 3 - (options.length % 3);

  if (data.mode === 'square') {
    console.log({ columFive, columnFour, columnTree });
  }

  let columnsNumber = 5;

  if (columFive !== 5) {
    if (
      columnFour === 4 ||
      (columnFour < columFive && columnFour < columnTree)
    ) {
      columnsNumber = 4;
    } else if (
      columnTree === 3 ||
      (columnTree < columFive && columnTree < columnFour)
    ) {
      columnsNumber = 3;
    }
  }

  if (!breakpoint.sm) {
    columnsNumber = 2;
  }

  return (
    <Form.Item
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      label={data.label}
      name={data.name}
      rules={[{ required: data.required, message: 'This field is required' }]}
      style={{ margin: marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
    >
      <Radio.Group
        ref={ref}
        className={`${modeClassName[data.mode]}${disabled ? ' read-only' : ''}`}
        style={
          data.mode === 'square'
            ? {
                display: 'grid',
                gridTemplateColumns: `repeat(${columnsNumber}, 1fr)`,
              }
            : undefined
        }
        options={options}
      />
    </Form.Item>
  );
};

export default FieldRadio;
