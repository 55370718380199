import { Form, Input } from 'antd';
import { FC } from 'react';
import { FormBuilderFieldInput } from 'src/types/formBuilder';

interface FieldInputProps {
  data: FormBuilderFieldInput;
  marginX: boolean;
  disabled: boolean;
}

const FieldInput: FC<FieldInputProps> = ({ data, marginX, disabled }) => {
  const form = Form.useFormInstance();

  return (
    <Form.Item
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      label={data.label}
      name={data.name}
      rules={[{ required: data.required, message: 'This field is required' }]}
      style={{ margin: marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
    >
      <Input
        placeholder={data.placeholder}
        type={data.inputType}
        readOnly={disabled}
        min={data.inputType === 'number' ? 0 : undefined}
        onChange={(event) => {
          if (
            data.inputType === 'number' &&
            Number(event.currentTarget.value) < 0
          ) {
            form.setFieldValue(data.name, 0);
          }

          if (data.mask === 'currency') {
            const value = event.currentTarget.value.replace(/\D/g, '');

            const newValue = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
            }).format(parseFloat(value || '0'));

            form.setFieldValue(data.name, newValue);
          }
        }}
      />
    </Form.Item>
  );
};

export default FieldInput;
