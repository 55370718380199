import { FC, useLayoutEffect, useState } from 'react';
import { Form, Typography } from 'antd';
import FormBuilderAnswers from 'src/components/FormBuilder/FormBuilderAnswers';
import { convertFormModelToFormBuilderForm } from 'src/components/Forms';
import { FormBuilderFormData } from 'src/types/formBuilder';

const { Title, Text } = Typography;

export interface FormBlockProps {
  state: any;
  form?: any;
  initState?: any;
  disabled?: boolean;
}

const FormBlock: FC<FormBlockProps> = ({
  state,
  form,
  initState,
  disabled,
}) => {
  const [formBuilder, setFormBuilder] = useState<FormBuilderFormData | null>(
    null
  );

  useLayoutEffect(() => {
    if (state.data) {
      setFormBuilder(convertFormModelToFormBuilderForm(state.data));
    }
  }, [state.data]);

  return (
    <div className={`form-view w-full`}>
      <div className="show-in-print mb-[20px]">
        <Text className="!text-blue-500">
          Project: {state?.data?.project?.title}
        </Text>

        <br />

        <Text className="!text-blue-500">
          Company : {state?.data?.project?.customer_business_name}
        </Text>
      </div>

      <Title className="mb-[10px]" level={3}>
        {state.data?.title}
      </Title>

      <span>{state.data?.description}</span>

      <div className="mb-[30px]" />

      {formBuilder && (
        <Form
          size="large"
          className="blue-labels default-form"
          form={form}
          initialValues={initState}
          layout="vertical"
        >
          <FormBuilderAnswers
            form={form}
            data={formBuilder}
            disabled={disabled}
          />
        </Form>
      )}
    </div>
  );
};

export default FormBlock;
