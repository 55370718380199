import { FC } from 'react';
import { Form } from 'antd';
import RichTextEditor from 'src/components/RichTextEditor';
import { FormBuilderFieldTextEditor } from 'src/types/formBuilder';

interface FieldTextEditorProps {
  data: FormBuilderFieldTextEditor;
  marginX: boolean;
  disabled: boolean;
}

const FieldTextEditor: FC<FieldTextEditorProps> = ({
  data,
  marginX,
  disabled,
}) => {
  return (
    <Form.Item
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      label={data.label}
      name={data.name}
      rules={[{ required: data.required, message: 'This field is required' }]}
      style={{ margin: marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
    >
      <RichTextEditor
        className="min-h"
        readonly={disabled}
        placeholder={data.placeholder}
      />
    </Form.Item>
  );
};

export default FieldTextEditor;
