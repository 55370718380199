import { FC } from 'react';
import { Divider } from 'antd';
import { FormBuilderFieldHeader } from 'src/types/formBuilder';

interface FieldHeaderProps {
  data: FormBuilderFieldHeader;
}

const FieldHeader: FC<FieldHeaderProps> = ({ data }) => {
  return (
    <div>
      <div className="mt-12 text-lg">{data.label}</div>

      <Divider />
    </div>
  );
};

export default FieldHeader;
