import { FC, ReactNode, useMemo } from 'react';
import { Form, Checkbox, CheckboxOptionType } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';
import { FaRadio } from 'react-icons/fa6';
import { FormBuilderFieldCheckbox } from 'src/types/formBuilder';

const modeClassName: Record<FormBuilderFieldCheckbox['mode'], string> = {
  simple: '',
  pill: 'checkbox-group-pill',
  square: 'checkbox-group-square',
};

interface FieldCheckboxProps {
  data: FormBuilderFieldCheckbox;
  marginX: boolean;
  disabled: boolean;
}

const listIcons: Record<string, ReactNode> = {
  google: <FaGoogle />,
  youtube: <FaYoutube />,
  facebook: <FaFacebook />,
  instagram: <FaInstagram />,
  radio: <FaRadio />,
  ott: <FaGoogle />,
  linkedin: <FaLinkedin />,
  tiktok: <FaTiktok />,
};

const FieldCheckbox: FC<FieldCheckboxProps> = ({ data, marginX, disabled }) => {
  const form = Form.useFormInstance();
  const breakpoint = useBreakpoint();

  const options = useMemo(() => {
    return data.options.map(
      (item): CheckboxOptionType => ({
        ...item,
        label: (
          <div style={{ textAlign: 'center' }}>
            {item.imageUrl ? (
              <>
                <img
                  src={item.imageUrl}
                  style={{
                    display: 'inline-block',
                    width: data.mode === 'square' ? '40px' : 16,
                    marginRight: data.mode !== 'square' ? 6 : undefined,
                    verticalAlign:
                      data.mode !== 'square' ? 'text-bottom' : undefined,
                  }}
                  alt="icon"
                />
                {data.mode === 'square' ? <br /> : undefined}
              </>
            ) : undefined}

            {item.icon ? (
              <>
                {listIcons[item.icon]}
                {data.mode === 'square' ? <br /> : undefined}
              </>
            ) : undefined}

            {item.label}
          </div>
        ),
        onChange: (event) => {
          if (!event.target.checked) {
            return;
          }

          const uniqueOptions = data.options.filter((item) => item.unique);

          if (uniqueOptions.length) {
            const optionTarget = uniqueOptions.find(
              (item) => item.value === event.target.value
            );

            if (optionTarget) {
              setTimeout(() => {
                form.setFieldValue(data.name, [optionTarget.value]);
              }, 0);
            } else {
              const selected = form.getFieldValue(data.name) as
                | string[]
                | undefined;

              const filtered = selected?.filter(
                (item) => !uniqueOptions.find((option) => option.value === item)
              );

              if (selected?.length !== filtered?.length) {
                setTimeout(() => {
                  form.setFieldValue(data.name, [
                    ...(filtered ?? []),
                    event.target.value,
                  ]);
                }, 0);
              }
            }
          }
        },
      })
    );
  }, [data.mode, data.name, data.options, form]);

  const columFive = 5 - (options.length % 5);
  const columnFour = 4 - (options.length % 4);
  const columnTree = 3 - (options.length % 3);

  if (data.mode === 'square') {
    console.log({ columFive, columnFour, columnTree });
  }

  let columnsNumber = 5;

  if (columFive !== 5) {
    if (
      columnFour === 4 ||
      (columnFour < columFive && columnFour < columnTree)
    ) {
      columnsNumber = 4;
    } else if (
      columnTree === 3 ||
      (columnTree < columFive && columnTree < columnFour)
    ) {
      columnsNumber = 3;
    }
  }

  if (!breakpoint.sm) {
    columnsNumber = 2;
  }

  return (
    <Form.Item
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      label={data.label}
      name={data.name}
      rules={[{ required: data.required, message: 'This field is required' }]}
      style={{ margin: marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
    >
      <Checkbox.Group
        className={`${modeClassName[data.mode]}${disabled ? ' read-only' : ''}`}
        style={
          data.mode === 'square'
            ? {
                display: 'grid',
                gridTemplateColumns: `repeat(${columnsNumber}, 1fr)`,
              }
            : undefined
        }
        options={options}
      />
    </Form.Item>
  );
};

export default FieldCheckbox;
