import axios from 'axios';
import environment from 'src/utils/environments';

const radarAxios = axios.create({
  baseURL: 'https://api.radar.io/v1/',
  headers: { Authorization: environment.REACT_APP_RADAR_KEY },
  withCredentials: false,
});

export default radarAxios;
