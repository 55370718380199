import { FC } from 'react';
import { unstable_useBlocker } from 'react-router-dom';
import { PiWarningCircleLight } from 'react-icons/pi';
import { FormInstance, Modal } from 'antd';

interface RedirectConfirmModelProps {
  form: FormInstance;
}

const RedirectConfirmModel: FC<RedirectConfirmModelProps> = ({ form }) => {
  let blocker = unstable_useBlocker(({ currentLocation, nextLocation }) => {
    return (
      !!Object.values(form.getFieldsValue()).find((value) =>
        Array.isArray(value) ? value.length : value
      ) && currentLocation.pathname !== nextLocation.pathname
    );
  });

  return (
    <Modal
      title={
        <>
          <PiWarningCircleLight className="mr-2 inline-block align-text-bottom text-2xl text-yellow-500" />
          Are you sure you want to leave?
        </>
      }
      open={blocker.state === 'blocked'}
      onOk={blocker.proceed}
      onCancel={blocker.reset}
      okText="Yes, leave this page"
      cancelText="No, stay on this page"
      closable={false}
      maskClosable={false}
    >
      <p className="mb-4">
        You have unsaved changes, if you leave you will lose all the
        information.
      </p>
    </Modal>
  );
};

export default RedirectConfirmModel;
