import { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import UploadDragger from 'src/components/FileUpload/UploadDragger';
import { FormBuilderFieldUpload } from 'src/types/formBuilder';
import { UploadFileApi } from 'src/types';

interface FieldUploadDraggerProps {
  value?: string[];
  onChange?: (values: string[]) => void;
}

const FieldUploadDragger: FC<FieldUploadDraggerProps> = ({ onChange }) => {
  const [uploads, setUploads] = useState<UploadFileApi[]>([]);

  useEffect(() => {
    onChange?.(
      uploads
        .filter((item) => item.status !== 'error' && item.status !== 'removed')
        .map((item) => item.response?.uuid ?? 'waiting')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads]);

  return (
    <UploadDragger
      maxSize="50MB"
      uploads={uploads}
      setUploads={setUploads}
      showDownloadIcon
      showRemoveIcon
    />
  );
};

interface FieldUploadProps {
  data: FormBuilderFieldUpload;
  marginX: boolean;
  disabled: boolean;
}

const FieldUpload: FC<FieldUploadProps> = ({
  data,
  marginX,
  disabled: _disabled,
}) => {
  return (
    <Form.Item
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      label={data.label}
      name={data.name}
      rules={[
        { required: data.required, message: 'This field is required' },
        {
          validator(_, value) {
            if (
              !value ||
              !Array.isArray(value) ||
              !value.find((item) => item === 'waiting')
            ) {
              return Promise.resolve();
            }

            return Promise.reject(new Error('Wait for all uploads to finish'));
          },
        },
      ]}
      style={{ margin: marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
    >
      <FieldUploadDragger />
    </Form.Item>
  );
};

export default FieldUpload;
