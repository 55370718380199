/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IoArrowBack } from 'react-icons/io5';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import dayjs, { Dayjs } from 'dayjs';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Skeleton,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import UploadDragger from 'src/components/FileUpload/UploadDragger';
import RedirectConfirmModel from 'src/components/RedirectConfirmModel';
import FormBuilderAnswers from 'src/components/FormBuilder/FormBuilderAnswers';
import Box from 'src/components/Box';
import { convertFormModelToFormBuilderForm } from 'src/components/Forms';
import { removeImage } from 'src/utils/stringHelpers';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import type { RootState } from 'src/store';
import type { UploadFileApi } from 'src/types';
import type { FormBuilderFormData } from 'src/types/formBuilder';
import listRequests from './listRequests.json';

const { Title } = Typography;

const FormRequestPage = () => {
  const [form] = useForm();
  const [formSentSuccess, setFormSentSuccess] = useState(false);
  const [hasFileLoading, setHasFileLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [uploads, setUploads] = useState<UploadFileApi[]>([]);
  const [formModel, setFormModel] = useState<any>(null);
  const [formBuilder, setFormBuilder] = useState<FormBuilderFormData | null>(
    null
  );
  const [formModalLoading, setFormModelLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const user = useSelector((globalState: RootState) => globalState.auth.user);

  const navigate = useNavigate();
  const params = useParams();
  const id = params.id ? parseInt(params.id) : 0;

  const request = listRequests.find((item) => item.id === id);

  const disableTargetDate = (current: Dayjs) => {
    const dayjsNow = dayjs().startOf('day');
    const day = dayjsNow.day();
    const minSpaceDays = 2;

    if (day === 0) {
      return current.subtract(minSpaceDays + 1, 'days').isBefore(dayjsNow);
    }

    if (day === 6 || day === 5 || day === 4) {
      return current.subtract(minSpaceDays + 2, 'days').isBefore(dayjsNow);
    }

    return current.subtract(minSpaceDays, 'days').isBefore(dayjsNow);
  };

  const fetchForm = async (id: string) => {
    if (formModalLoading) return;

    setFormModelLoading(true);

    try {
      const response = await apiRequests.get(`${apiRoutes.FORMS}/${id}`);

      const model = response.data.data;

      setFormModel(model);

      setFormBuilder(convertFormModelToFormBuilderForm(model));
    } catch (error) {
      asyncErrorHandler(error);
      setFormBuilder(null);
    } finally {
      setFormModelLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (request) {
      fetchForm(request.formId);
    }
  }, [request]);

  useEffect(() => {
    const submitHandler = async () => {
      if (!executeRecaptcha) {
        message.info('Loading recaptcha, please try again');
        return;
      }

      const values = await form.validateFields();

      try {
        values.recaptcha_token = await executeRecaptcha();
      } catch (error) {
        message.error('Problem with recaptcha, please try again');
        return;
      }

      values.uploads = uploads.map((item) => item.response?.uuid);

      values.target_date = values.target_date?.format('YYYY-MM-DD');

      const questions = formModel.questions.map((item: any) => {
        item.answer = values[item.id];
        delete values[item.id];
        return item;
      });

      values.description = JSON.stringify(questions);

      if (user) {
        values.from_email = user.email;
        values.company_name = user.contact?.customer?.business_name;
      }

      try {
        await apiRequests.post(apiRoutes.PROJECT_REQUEST, values);

        form.resetFields();

        setUploads([]);

        setFormSentSuccess(true);
      } catch (error) {
        asyncErrorHandler(error);
      }
    };

    if (!hasFileLoading && submitLoading) {
      submitHandler().finally(() => setSubmitLoading(false));
    }
  }, [executeRecaptcha, form, hasFileLoading, submitLoading, uploads]);

  return (
    <Box>
      <RedirectConfirmModel form={form} />

      <div className="py-2">
        {!formSentSuccess ? (
          <>
            <div className="mb-6 flex items-center justify-between">
              <Button
                type="link"
                size="large"
                onClick={() => {
                  navigate('/request');
                }}
                style={{ paddingLeft: 0 }}
                icon={<IoArrowBack />}
              >
                Back
              </Button>

              <Title level={1} className="!mb-0 text-center !text-blue-700">
                Form Request
              </Title>

              <div className="invisible w-[80px]"></div>
            </div>

            <Form
              className="default-form"
              layout="vertical"
              form={form}
              size="large"
              onFinish={() => setSubmitLoading(true)}
            >
              {!user && (
                <>
                  <Form.Item
                    name="from_email"
                    label="Your email"
                    rules={[
                      { required: true, message: 'Please enter your email' },
                    ]}
                  >
                    <Input bordered={false} />
                  </Form.Item>

                  <Form.Item
                    name="company_name"
                    label="Company name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the name of your company',
                      },
                    ]}
                  >
                    <Input bordered={false} />
                  </Form.Item>
                </>
              )}

              {formModalLoading && <Skeleton className="mb-6" />}

              {!formModalLoading && formBuilder ? (
                <>
                  <FormBuilderAnswers form={form} data={formBuilder} />

                  <UploadDragger
                    maxSize="50MB"
                    uploads={uploads}
                    setLoading={setHasFileLoading}
                    setUploads={setUploads}
                    showDownloadIcon
                    showRemoveIcon
                    onRemove={(upload) => {
                      const newText = removeImage(
                        form.getFieldValue('description'),
                        upload.response?.url || ''
                      );
                      form.setFieldValue('description', newText);
                    }}
                  />

                  <div className="mb-4 mt-1 text-xs text-gray-400">
                    If you need to upload a lot of files, ask our team for a
                    special link where you can upload unlimited files of any
                    size.
                  </div>

                  <Form.Item
                    name="target_date"
                    label="Set a due date - Minimum request time 48h"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the due date of the request',
                      },
                    ]}
                    extra={
                      <div className="mt-1 text-xs text-gray-400">
                        If you need it to be urgent, just submit the form and
                        send an email to support@savageglobalent.com asking to
                        speed up this request.
                      </div>
                    }
                  >
                    <DatePicker
                      className="w-52"
                      showToday={false}
                      format="MM/DD/YYYY"
                      disabledDate={disableTargetDate}
                    />
                  </Form.Item>

                  <div className="mb-2 text-xs text-gray-400">
                    If your email is not found in our system, you will receive
                    an email asking you to register a user so that you can
                    receive notifications and follow the progress of your
                    request.
                  </div>

                  <div className="mx-auto max-w-[160px]">
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      loading={submitLoading}
                      block
                    >
                      Submit
                    </Button>
                  </div>
                </>
              ) : undefined}
            </Form>
          </>
        ) : (
          <Alert
            message="Form request sent successful"
            description={
              <div>
                <div className="mb-2">
                  Roaring good news! Request received. Check Lionshare for
                  updates within 24-48 hours.
                </div>
                <Button type="primary" onClick={() => navigate('/request')}>
                  Send another request
                </Button>
              </div>
            }
            type="success"
            showIcon
          />
        )}
      </div>
    </Box>
  );
};

export default FormRequestPage;
