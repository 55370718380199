import Card from 'src/components/Card';
import Box from 'src/components/Box';
import { FormBuilderData } from 'src/types/formBuilder';
import listFormsData from './listForms.json';

const listForms = listFormsData as FormBuilderData[];

// listForms.map((item) =>
//   item.wizardForm.map((x) => x.form.inputs.map((y) => y.type))
// );

const FormRequest = () => {
  return (
    <Box>
      <h1 className="mb-2 text-xl font-light text-blue-700 lg:text-2xl">
        Select a request
      </h1>

      <div className="grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {listForms.map(
          (request) =>
            !request.disabled && (
              <Card
                key={request.name}
                name={request.name}
                link={`/form-request/${request.id}`}
                imageUrl={request.imageUrl}
                tags={request.tags}
                buttonName="Request"
                ribbon={
                  request.timeEstimated && `Starting ${request.timeEstimated}`
                }
              >
                {request.description}
              </Card>
            )
        )}
      </div>
    </Box>
  );
};

export default FormRequest;
