import { FC } from 'react';
import { Form, DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { FormBuilderFieldDate } from 'src/types/formBuilder';
import { TbCalendarEvent } from 'react-icons/tb';

interface FieldDateProps {
  data: FormBuilderFieldDate;
  marginX: boolean;
  disabled: boolean;
}

const FieldDate: FC<FieldDateProps> = ({ data, marginX, disabled }) => {
  return (
    <Form.Item
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      label={data.label}
      name={data.name}
      rules={[{ required: data.required, message: 'This field is required' }]}
      style={{ margin: marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
    >
      <div
        className="flex flex-wrap items-center rounded px-3 py-1"
        style={{
          border: '1px solid rgba(0, 68, 204, 0.2)',
          background: 'rgba(0, 68, 204, 0.05)',
        }}
      >
        <TbCalendarEvent className="text-[#0044CC]" />

        {data.mode === 'simple' && (
          <DatePicker
            className={disabled ? 'read-only' : undefined}
            style={{
              border: 'none',
              background: 'transparent',
              boxShadow: 'none',
            }}
            format="MMM DD, YYYY"
            suffixIcon={null}
            placeholder={data.placeholder}
            disabledDate={(value) => value.isBefore(dayjs().startOf('day'))}
          />
        )}

        {data.mode === 'range' && (
          <DatePicker.RangePicker
            className={`ant-picker-short-format${disabled ? ' read-only' : ''}`}
            style={{
              border: 'none',
              background: 'transparent',
              boxShadow: 'none',
            }}
            suffixIcon={null}
            separator="-"
            format="MMM DD, YYYY"
            disabledDate={(value) => value.isBefore(dayjs().startOf('day'))}
          />
        )}

        {data.mode === 'time' && (
          <TimePicker className={disabled ? 'read-only' : undefined} />
        )}
      </div>
    </Form.Item>
  );
};

export default FieldDate;
