import { FC, useRef, useState } from 'react';
import { Button, Drawer, Popconfirm, Row, Skeleton, Space } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { IoCloseOutline, IoPrintOutline } from 'react-icons/io5';
import FormBlock from 'src/components/FormBlock/FormBlock';
import StatusTag from 'src/components/StatusTag';
import AxiosErrorResult from 'src/components/AxiosErrorResult';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import useFormDrawer from './useFormDrawer';

interface FormDrawerProps {
  id: string;
}

const FormDrawer: FC<FormDrawerProps> = ({ id }) => {
  const [open, setOpen] = useState(true);
  const { form, state, onClose, updateSingleForm } = useFormDrawer({ id });
  const formBodyRef = useRef(null as any);

  const { data, status, loading, saving, axiosError } = state;

  const handlePrint = useReactToPrint({
    content: () => formBodyRef.current,
    onAfterPrint: () => {
      document.title = 'Savage Global Marketing';
    },
  });

  return (
    <Drawer
      open={open}
      width={600}
      headerStyle={{ background: '#F3F5FD' }}
      bodyStyle={{ paddingBottom: 40, background: '#F3F5FD' }}
      footerStyle={{ background: '#F3F5FD' }}
      closable={false}
      onClose={() => setOpen(false)}
      afterOpenChange={(item) => {
        if (!item) onClose();
      }}
      destroyOnClose
      title={
        <div className="flex flex-wrap justify-between gap-2">
          <div className="flex items-center gap-1">
            <IoCloseOutline
              onClick={onClose}
              className="cursor-pointer text-[20px]"
            />
            <span>{!axiosError ? 'Form' : 'Form not found'}</span>
          </div>

          {!axiosError ? (
            <>
              <Button
                onClick={onClose}
                type="link"
                className="order-1 sm:order-2"
              >
                Cancel
              </Button>

              <div className="order-2 flex w-full flex-wrap justify-end gap-4 sm:order-1 sm:w-auto sm:flex-1">
                {status !== 'completed' && (
                  <>
                    <Button
                      className="w-full sm:w-auto"
                      disabled={saving}
                      onClick={() => {
                        const values = form.getFieldsValue();
                        updateSingleForm(values);
                      }}
                    >
                      Save and continue later
                    </Button>
                    <Popconfirm
                      title="Do you want to submit this form?"
                      placement="bottom"
                      onConfirm={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            updateSingleForm(values, 'completed');
                          })
                          .catch((error) => {
                            asyncErrorHandler(error);
                          });
                      }}
                      disabled={saving}
                    >
                      <Button
                        className="w-full sm:w-auto"
                        disabled={saving}
                        type="primary"
                      >
                        Finish and submit
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </div>
            </>
          ) : undefined}
        </div>
      }
    >
      {loading ? <Skeleton /> : undefined}

      {!loading && !axiosError ? (
        <>
          <div style={{ position: 'relative' }}>
            <Row justify="end">
              <Space>
                {status && (
                  <Space>
                    Status: <StatusTag value={status} />
                  </Space>
                )}
                <IoPrintOutline
                  size={20}
                  onClick={() => {
                    document.title = `SGM - ${data?.project?.title} - ${data?.project?.customer?.business_name}`;
                    setTimeout(() => {
                      handlePrint();
                    }, 200);
                  }}
                  className="cursor-pointer text-blue-500"
                />
              </Space>
            </Row>
            <div className="print-form-styles" ref={formBodyRef}>
              <FormBlock
                disabled={status === 'completed'}
                form={form}
                initState={data?.answers}
                state={state}
              />
            </div>
          </div>
        </>
      ) : undefined}

      {axiosError ? <AxiosErrorResult error={axiosError} /> : undefined}
    </Drawer>
  );
};

export default FormDrawer;
