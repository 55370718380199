import { FC } from 'react';
import { Form, Input } from 'antd';
import { FormBuilderFieldTextArea } from 'src/types/formBuilder';

const { TextArea } = Input;

interface FieldTextAreaProps {
  data: FormBuilderFieldTextArea;
  marginX: boolean;
  disabled: boolean;
}

const FieldTextArea: FC<FieldTextAreaProps> = ({ data, marginX, disabled }) => {
  return (
    <Form.Item
      className={data.optionalLabel ? 'ant-optional-label' : undefined}
      label={data.label}
      name={data.name}
      rules={[{ required: data.required, message: 'This field is required' }]}
      style={{ margin: marginX ? '-8px 18px 16px 18px' : '0 0 16px' }}
    >
      <TextArea
        style={{ minHeight: 150 }}
        placeholder={data.placeholder}
        readOnly={disabled}
      />
    </Form.Item>
  );
};

export default FieldTextArea;
