import { FC, useEffect, useState } from 'react';
import { Dropdown, Input, InputProps, MenuProps } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { LiaMapMarkerAltSolid } from 'react-icons/lia';
import { AxiosResponse } from 'axios';
import { debounceAsync } from 'src/utils/debounce';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import radar from 'src/utils/radar';

interface SearchAutocompleteProps {
  value?: InputProps['value'];
  size?: InputProps['size'];
  onChange?: InputProps['onChange'];
  onSelect?: (address: any) => void;
}

const debounceSearch = debounceAsync<AxiosResponse>((searchTerm: string) => {
  return radar.get('/search/autocomplete', {
    params: {
      query: searchTerm,
      limit: 6,
      countryCode: 'US',
    },
  });
}, 400);

const SearchAutocomplete: FC<SearchAutocompleteProps> = ({
  value,
  size,
  onChange,
  onSelect,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [fetchingAddress, setFetchingAddress] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const items: MenuProps['items'] = addresses
    .filter((item: any) => item.countryCode)
    .map((item: any) => ({
      key: `${item.latitude}_${item.longitude}`,
      style: { padding: '6px 16px' },
      icon: <LiaMapMarkerAltSolid style={{ fontSize: 16, marginRight: 8 }} />,
      onClick: () => onSelect?.(item),
      label: (
        <span>
          <strong
            style={{
              paddingRight:
                item.addressLabel !== item.city && item.city ? 6 : undefined,
            }}
          >
            {item.addressLabel}
          </strong>
          {item.addressLabel !== item.city && item.city ? ` ${item.city}` : ''},{' '}
          {item.stateCode} {item.postalCode} {item.countryCode}
        </span>
      ),
    }));

  useEffect(() => {
    if (!showDropdown) {
      return;
    }

    if (!value || value.toString().length < 3) {
      setAddresses([]);
      return;
    }

    setFetchingAddress(true);

    debounceSearch(value)
      .then((response) => {
        setAddresses(response.data.addresses);
      })
      .catch((error) => asyncErrorHandler(error))
      .finally(() => setFetchingAddress(false));
  }, [showDropdown, value]);

  return (
    <Dropdown
      open={
        showDropdown &&
        !!value &&
        value.toString().length >= 3 &&
        addresses.length > 0
      }
      menu={{ items }}
    >
      <Input
        value={value}
        size={size}
        onChange={onChange}
        suffix={
          <Loading3QuartersOutlined
            style={{ visibility: fetchingAddress ? 'visible' : 'hidden' }}
            spin
          />
        }
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setTimeout(() => setShowDropdown(false), 300)}
      />
    </Dropdown>
  );
};

export default SearchAutocomplete;
